.live-bet-page {

  .first-con,
  .card {
    border: 0;
  }
}

.dropdown-wrapper {
  background-image: url("../../assets/images/other/liveBitBackground.png");
  background-repeat: round;
  background-size: cover;
  // max-height: 68.2rem;
  border-bottom: 0.1875rem solid;
}

.middle-nav {
  display: flex;
  background-color: #000000;
  padding-top: 2rem;
  margin-top: -0.25rem;
  width: 100%;
  overflow-x: auto;

  button {
    color: #98989A;
    border: none;
    // border-left: 1px solid black;
    margin-left: 0.1875rem;
    height: 6rem;
    font-size: 2.625rem;
    padding: 0 1.875rem;
    background-image: url("../../assets/images/other/liveBitButton-black.png");
    background-repeat: round;
    font-weight: 600;
    flex-shrink: 0;
    padding-bottom: 0.1rem;

    // .active-container {
    //   background: linear-gradient(0deg, #d8bc6f, #aa8f60 50%, #d6c8a5);
    // }
    &.active {
      background: linear-gradient(0deg, #d8bc6f, #aa8f60 50%, #d6c8a5);
      // background-repeat: no-repeat;
      // background: url('../../assets/images/imgs/active-active.png');
      background: url('../../assets/images/imgs/qq.png');
      // padding: 1rem;
      // background: #faf8cb;
      background-repeat: round;
      color: #000000;
      outline: none;
      // border: 2px solid;
      // border-top: 0;
      // padding: 0;
      // min-width: 10rem;
      // height: 6rem;

      // outline-color: #faf8cb;
      // outline: 4px auto #faf8cb;
      // outline-offset: -4px;
      // border-bottom: 1px solid #faf8cb;
      // border-left: 1px solid #faf8cb;
      // border-right: 1px solid #faf8cb;
    }

    //   .active-container {
    //     display: flex;
    //     width: 61%;
    //     height: 101%;
    //     background: #000000;
    //     padding: 0 1.875rem;
    //     background: linear-gradient(0deg, #d8bc6f, #aa8f60 50%, #d6c8a5);
    //     margin-left: -3px;
    //     align-items: center;
    //     padding: 0 1.875rem;
    //     height: 6rem;
    //     min-width: 10rem;
    //     span {
    //       padding: 0.9rem 2rem;
    //       display: flex;
    //     }
    // }

    // &.active::after {
    //   content: "\a0";
    //   display: block;
    //   padding: 2px 0;
    //   line-height: 1px;
    //   border-bottom: 1px dashed #000;
    // }
  }
}


.bet-dropdown {
  .bet-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    color: #fff;
    height: 7.125rem;

    // @media(max-width:$bp-medium) {
    //   padding-left: 5px;
    //   padding-right: 5px;
    // }

    .bet-dropdown-para {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 2.625rem;
      color: #d1cecf;

      .bet-dot {
        height: 0.625rem;
        width: 0.625rem;
        background-color: #afb3be;
        display: inline-block;
        margin-top: 0.3rem;
      }

      p {
        margin-left: 1.25rem;
      }
    }

    .bet-arrow-button {
      border: none;
      // background-color: rgba(255, 255, 255, 0.2);
      background: url('../../assets/images/imgs/aa.png');
      background-repeat: round;
      width: 4.25rem;
      height: 4.25rem;
      border-radius: 0.25rem;

      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.125rem;

      img {
        width: 2.43rem;
        height: 1.437rem;
      }

      &:active {
        -webkit-filter: brightness(125%);
        filter: brightness(125%);
        background: rgb(104 77 82);
      }

      // &:hover {
      //   -webkit-filter: brightness(130%);
      //   filter: brightness(130%);
      // }
    }
  }

  &:first-child {
    .bet-dropdown-header {
      height: 8.125rem;
    }
  }

  &:last-child {
    // padding-bottom: 1rem;
    // border-bottom: 0.1875rem solid;
  }

  .bet-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.125rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 6.6rem;
    background: url("../../assets/images/other/skew-container.png"), rgba(255, 255, 255, 0.102);
    background-repeat: round;

    // @media(max-width:$bp-medium) {
    //   margin-left: 5px;
    //   margin-right: 5px;
    // }
      &:last-child {
        margin-bottom: 0.7rem;
      }

    .bet-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      height: 100%;

      .bet-btn-skew {
        display: flex;
        justify-content: space-between;
        width: 49.5%;
        align-items: center;

        p {
          margin: 0;
          color: #d1cecf;
          font-size: 2.45rem;
          margin-top: -0.1rem;
          white-space: nowrap;
          min-width: 5rem;
          width: 21rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .add-button {
          border: none;
          background: none;
          color: #fff;
          width: 9.8rem;
          height: 9.2rem;
          font-family: RobotoRegular;
          font-size: 2.125rem;
          // padding-top: 0rem;
          &:first-child {
            // transform: translateY(0.25rem);
          }

          // z-index: 1;
          span {
            font-family: RobotoRegular;
            color: #d1cecf;
            font-size: 2.45rem;
            font-weight: 600;
            // display: block;
          }

          &.red-button {
            background: url("../../assets/images/imgs/red-btn.png");
            background-repeat: round;
            margin: -1rem;
            padding-top: 0.2rem;
            width: 11.8rem;
            outline: none;
            position: relative;

            .red-arrow {
              width: 4.6875rem;
              height: 2.0625rem;
              margin-bottom: 1rem;
              position: absolute;
              top: 1rem;
              right: 3.5rem;
              animation: redUpAnimation 1s infinite;
            }

            @keyframes redUpAnimation {
              0% {
                top: 0.8rem;
              }

              50% {
                top: 1.1rem;
              }

              100% {
                top: 0.8rem;
              }
            }
          }

          &.blue-button {
            background: url("../../assets/images/imgs/blue-btn.png");
            background-repeat: round;
            position: relative;
            margin: 0 -1rem;
            width: 11.8rem;
            padding-top: 0.2rem;

            .blue-arrow {
              width: 4.6875rem;
              height: 2.0625rem;
              position: absolute;
              top: 6rem;
              right: 3.5rem;
              animation: blueDownAnimation 1s infinite;
            }

            @keyframes blueDownAnimation {
              0% {
                top: 6.3rem;
              }

              50% {
                top: 6rem;
              }

              100% {
                top: 6.3rem;
              }
            }
          }
        }

        &:nth-child(2) {
          justify-content: center;
          width: 17rem;
          height: 100%;

          .add-button {
            width: auto;
          }
        }

        &:nth-child(3) {
          width: 47%;

          .add-button {
            color: #d1cecf;
            margin-left: -1.0625rem;
            outline: none;
          }
        }

        &:nth-child(1),
        &:nth-child(3) {
          width: 70%;
          margin-right: -2rem;
          padding-right: 1.7rem;
          margin-left: -1.4rem;
          padding-left: 1.5rem;
          // z-index: 1;
          height: 100%;
        }

        &.bet-btn-numbers {
          .add-button {
            color: #d1cecf;
            outline: none;
          }
        }
      }
    }
  }

  .bet-button-container.first-section-red {
    background: url("../../assets/images/other/skew-container-red.png"), rgba(255, 255, 255, 0.102);
    background-repeat: round;
    flex-shrink: 0;

    .bet-btn-skew {
      &:first-child {
        p {
          color: #fff;
        }
      }
    }
  }
}

.middle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.75rem;
  height: 2.1875rem;
  padding-left: 0;
  clip-path: polygon(0 0, 100% 0, 86% 100%, 14% 100%);
  font-family: RobotoMedium;
  // font-size: 14px;
  background-color: #9b2f30;
}

.blue-section {
  background: linear-gradient(270deg, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
  height: 6.6rem;
  width: 20rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
  // z-index: 3;
}