@keyframes anim {
  0% {
    top: 100%;
  }
}

@keyframes anim2 {
  0% {
    top: 0%;
  }
}

.cart-scroll {
  position: fixed;
  // width: 77.625rem;
  width: 77.8875rem;
  height: 100vh;
  z-index: 10000;
  top: 0;
  overflow-y: scroll;
  // background: #000;
  // opacity: 0.7;
  background: rgba(0, 0, 0, 0.600);

  ::-webkit-scrollbar {
    height: 0.0625rem;
    /* height of horizontal scrollbar ← You're missing this */
    width: 0.25rem;
    /* width of vertical scrollbar */
    border: 0.0625rem solid transparent;
  }

  ::-webkit-scrollbar:vertical {
    display: none;
  }
}

.cart-cart {
  position: absolute;
  z-index: 16;
  top: 0%;
  width: 77.625rem;
  margin: auto;
  height: auto;
  opacity: 1;
  overflow: unset;
  animation: anim 0.7s ease-out 0s normal;
}

.close-animation {
  position: absolute;
  z-index: 16;
  top: 100%;
  width: 77.625rem;
  margin: auto;
  height: auto;
  // opacity: 1;
  overflow: unset;
  animation: anim2 0.7s ease-out 0s normal;
}

.cart-btn {
  background-image: url('../../assets/images/imgs/button-bg.png');
  background-repeat: round;
  border-radius: 1rem;
  color: #e5b877;
  outline: none;
  border: 0;
  height: 6.6875rem;
  font-size: 3rem;
  margin: 0.78125rem 0.5rem;
  width: 23.7rem;
  padding: 0;

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
  }

  // &:focus {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }
}

// .cart-btn:focus {
//   filter: brightness(130%);
// }

.cart-btn-photo {
  padding: 0;
  display: flex;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/imgs/btn.png');
  // background-color: #000;
  box-shadow: 0.0625rem 0.0625rem 0.3125rem -0.1875rem rgb(0 0 0 / 75%);
  color: #888889;
  background-repeat: round;
  border: 0;
  width: 35.625rem;
  height: 8.1875rem;
  font-size: 3rem;
  margin: 0 1.0625rem;

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
  }

  // &:hover {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }

  img {
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    margin-top: 0.2rem;
  }
}

.active {
  background: url('../../assets/images/imgs/active-bg.png');
  background-repeat: round;
  color: #000;
}

.line {
  height: 0.1875rem;
  background: linear-gradient(to right, #2e2e37, #58585f 50%, #2e2e37);
}

.card-content {
  background: linear-gradient(90deg, #1a1a20, #18181b 50%, #161616);

  .cart-slide {
    height: 7.5625rem;

    p {
      font-size: 3rem;
    }
  }

  .blue {
    border: 0;
  }

  .green {
    border: 0;
  }

  .line {
    margin: 0 2.1875rem;
    height: 0.18rem;
    width: calc(100% - 4.7rem);
    background: linear-gradient(to right, #35353a, #272729 50%, #1d1d1d);
  }
}

.cart-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  color: #909090;
  background: linear-gradient(to right, #1a1a20, #18181b 50%, #161616);
  height: 7.75rem;
  font-size: 3rem;

  p {
    font-size: 3rem;
  }
}

.id-section {
  // background: url('../../assets/images/imgs/id-background.png');
  background: linear-gradient(to right, #2a2a34, #212128 50%, #1a1a20);
  border-bottom: 0.1875rem solid #000;
}

.card-container {
  background: linear-gradient(90deg, #1a1a20, #18181b 50%, #161616);

  p {
    margin: 0;
    margin-bottom: 0.25rem;
  }

  .cart-other-slide {
    height: 7.5rem;
  }

  .line {
    margin: 0 2.1875rem;
    height: 0.18rem;
    width: calc(100% - 4.7rem);
    background: linear-gradient(to right, #35353a, #272729 50%, #1d1d1d);
  }

}

.cart-main-slide {
  background: linear-gradient(to right, #2a2a34, #22222a 50%, #1a1a20);
  color: #fff
}

.yellow {
  border-bottom: 0.1875rem solid #000;

  .slide-num {
    color: #e9ca4e;
    font-family: RobotoRegular;
    font-size: 3.4375rem;
    font-weight: bold;
  }
}

.blue {
  border-bottom: 0.1875rem solid #000;
  height: 7.75rem;

  .slide-num {
    color: #4195c1;
    font-size: 3.4375rem;
  }
}

.green {
  border-bottom: 0.1875rem solid #000;
  height: 7.75rem;

  .slide-num {
    color: #00ff05;
    font-size: 3.4375rem;
  }
}

.slide-num {
  color: #e5b877;
  font-family: RobotoRegular;
}

.cart-button-text {
  background-color: #22222A;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #222222;
}

.cart-button-repeat {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  height: 6.625rem;
  border-radius: 0.625rem;
  color: #D7BA47;
  // margin-right: 0.3rem;
  width: 55rem;


  .number {
    height: 5.625rem;
    padding-right: 1.625rem;
    font-size: 3.4375rem;
    border: 0;
    text-align: right;
    outline: none;
    width: 41rem;
    ;
  }

  input {
    font-family: RobotoRegular;
  }
}


.img-cart-rep {
  background: url('../../assets/images/imgs/repeat-bg.png');
  background-repeat: round;
  height: 6.25rem;
  width: 6.25rem;
  margin-left: 0.1875rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4.0625rem;
  }

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
  }

  // &:hover {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }
}

.cart-p-repeat {
  margin: 0;
  color: #fff;
  flex-basis: 40%;

}

.id-wrapper {
  .cart-other-slide {
    font-size: 3rem;
    border-bottom: 1rem solid #000;
    height: 7.5rem;
  }

  .cart-items {
    width: 100%;
    background-color: #000;
    background: url('../../assets/images/imgs/card-cart-bg.png');
    position: relative;
    font-size: 3rem;
    background-repeat: round;
    padding-bottom: 1rem;
    border-bottom: 0.1875rem solid black;
    min-height: 13.0625rem;
    // max-height: 26.0625rem;
    overflow: hidden;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    background-size: cover;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .slide-num {
    color: #909090;
    font-size: 2.625rem;
    font-family: 'RobotoRegular';
  }
}


.cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: linear-gradient(to bottom, #303039, #303038 50%, #2d2d35 100%);
  background: linear-gradient(180deg, #4d4d5c 0, #3c3c47 50%, #2d2d35 100%);
  color: #fff;
  border-bottom: 0.1875rem solid #000000;
  height: 8.625rem;
}

.cart-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 2.25rem;
  border-right: 0.1875rem solid #000000;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    font-weight: bold;

    p {
      font-family: 'RobotoRegular';
      margin-left: 1.8125rem;
      margin-right: 1.875rem;
      font-size: 3rem;
      // margin-top: 2.5rem;
      font-weight: 700;
      letter-spacing: 0.1875rem;
    }

    img {
      margin: 0;
      margin-right: 0.0625rem;

      &.cart-icon {
        margin-bottom: 0.0625rem;
        margin-right: 0;
        height: 5rem;
        width: 5.1875rem;
      }
    }
  }

  .clock {
    display: flex;
    flex-direction: column;
    margin-right: 1.8rem;
    // font-size: 12px;
    margin-bottom: 0.1875rem;

    .date {
      color: #909090;
      width: 100%;
      margin: 0;
      font-size: 2.5rem;
      font-family: NotoSansCJKkrRegular;
    }

    .time {
      display: flex;
      justify-content: flex-end;
      color: #e5b877;
      width: 100%;
      margin: 0;
      margin-top: -0.1875rem;
      font-family: RobotoRegular;
      font-size: 2.5rem;

      img {
        width: 2.3rem;
        height: auto;
        margin-right: 0.5rem;
      }
    }
  }
}

.cartBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8.625rem;

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
    background: #4c4c5a9e;
  }

  .line {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.1875rem;
    height: 100%;
    background: linear-gradient(to bottom, #6e6e81 0, #626273 50%, #545462 100%);
  }
}

.cart-arrow {
  width: 8.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: initial;
  border: none;
}

.arrow {
  width: 3rem;
  // height: 1.875rem;
  margin-bottom: -0.5rem;
  transform: rotate(180deg);
  // &:hover {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }
}

.cart-num {
  width: 4.125rem;
  height: 4.125rem;
  background-color: #f15325;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.62rem;
  font-family: RobotoRegular;
  // margin-top: -0.1rem;
}


.cart-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../assets/images/imgs/cart-card-bg.png');
  background-repeat: round;
  color: #909090;
  min-height: 17.6875rem;
  font-size: 3rem;
  padding-bottom: 0.125rem;
  border-bottom: 0.1875rem solid #000;

  .cart-items {
    width: 100%;
    background-color: #000;
    // height: 17.5rem;
    background: url('../../assets/images/imgs/card-cart-bg.png');
    position: relative;
    font-size: 3rem;
    background-repeat: round;
    padding-bottom: 1rem;
    border-bottom: 0.1875rem solid black;
    min-height: 17.5rem !important;
    // max-height: 26.0625rem;
    overflow: hidden;
    padding-right: 4rem;
    padding-right: 7rem;
    padding-left: 2.4rem;
    background-size: cover;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
}

.cart-name div:last-child {
  border: none;
}

.cart-items-team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-left: 2.4rem;

  p {
    margin: 0;
    color: white;
    // margin-top: 1.125rem;
    margin-bottom: 1rem;
    margin-left: -0.4rem;
  }

  .close {
    position: absolute;
    right: 2.1875rem;
    top: 1.7rem;
    background: bottom;
    border: 0;
    padding: 0;

    &:active {
      -webkit-filter: brightness(130%);
      filter: brightness(130%);
    }

    // &:hover {
    //   -webkit-filter: brightness(130%);
    //   filter: brightness(130%);
    // }

    img {
      width: 3rem;
      height: 3.0625rem;
    }
  }
}

.sub-cart-items {
  height: 4.375rem;
}

.margin {
  margin-top: -0.25rem;
}


.cart-btns {
  padding: 1.34375rem 1.625rem 1rem 1.6875rem;
  border-top: 0.1875rem solid;
  height: 19.6875rem;
  background: linear-gradient(to right, #1a1a20, #18181a 50%, #161616);

  .active {
    background: url('../../assets/images/imgs/btn2.png');
    background-repeat: round;
    color: #212121;
    border-radius: 0;
    font-weight: 600;
  }
}

.cart-two-btns {
  display: flex;
  padding-top: 2.1875rem;
  background: linear-gradient(to right, #1a1a20, #18181a 50%, #161616);
  border-top: 0.1875rem solid;
  padding-bottom: 2.5625rem;
  height: 15.0625rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  border-bottom: 1.9375rem solid;

  p {
    font-weight: 600;
  }

  .active {
    background: url('../../assets/images/imgs/btn1.png');
    background-repeat: round;
    color: #212121;
    border-radius: 0;
  }
}


.cart-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #4d4d5c 0, #3c3c47 50%, #2d2d35 100%);
  height: 8.562rem;
  border-bottom: 0.1875rem solid;
  padding-right: 2.25rem;

  .line {
    height: 0.1875rem;
    background: linear-gradient(to right, #383842, #72727a 50%, #383842);
  }
}

.cart-mark,
.cart-repeat {
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
  }

  // &:hover {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }
}

.cart-mark {
  color: #fff;
  font-size: 3rem;

  p {
    font-weight: 600;
  }

  img {
    width: 9.375rem;
    height: 2.875rem;
    padding: 0 2.1875rem;
  }
}

.cart-repeat {
  background-image: url('../../assets/images/imgs/rep-bg.png');
  background-repeat: round;
  width: 14rem;
  height: 6.25rem;
  // border-radius: 1rem;
  font-size: 4rem;

  p {
    font-size: 3.4375rem;
    font-family: 'RobotoRegular';
    color: #212121;
    font-weight: 600;
    margin-top: 3.7rem;
  }

  img {
    width: 5rem;
    height: 4.1875rem;
    margin-left: 1rem;
  }
}


.cart-sub-content {
  width: 100%;
  background: url('../../assets/images/imgs/cart-card-bg.png');
  background-repeat: round;
  color: #909090;
  min-height: 3.4375rem;
  color: #959595;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 0.0625rem solid black;
  height: 13.0625rem;
  font-size: 4rem;
}


.margin-bottom {
  margin-bottom: 1rem;
  margin-left: -0.4rem;
}

.arrow-card {
  margin: 0.4rem;
  margin-left: 2rem;
}

.cart-button-container {
  background: linear-gradient(90deg, #1a1a20, #18181b 50%, #161616);
  display: flex;
  padding: 2.0625rem 2.125rem 1.9375rem 2.1875rem;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.cart-button-container button {
  padding: 0;
  width: 73.375rem;
  height: 12.5rem;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/images/imgs/bg.png');
  background-repeat: round;
  border: 0;
  color: #222222;
  padding-bottom: 0.375rem;
  font-size: 4rem;

  .button-icon {
    margin-right: 2.1875rem;
    width: 3.3125rem;
    height: 3.125rem;
  }

  &:active {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
  }

  // &:hover {
  //   -webkit-filter: brightness(130%);
  //   filter: brightness(130%);
  // }
}

.cart-name2 {
  height: 13.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../../assets/images/imgs/cart-card-bg.png');
  background-repeat: round;
  color: #909090;
  // min-height: 17.6875rem;
  font-size: 3rem;
  padding-bottom: 0.125rem;
  border-bottom: 0.1875rem solid #000;
}