@font-face {
  font-family: "NotoSansCJKkrRegular";
  src: url("./assets/fonts/NotoSansCJKkr-Regular.otf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RobotoRegular";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /* width: 375px; */
  padding: 0;
  margin-bottom: 9rem;

  font-family: NotoSansCJKkrRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1242px;
  margin: auto;
  background: #000;
}

body p, button, span, div {
  font-family: NotoSansCJKkrRegular;
}

button:focus {
  outline: none;
}

img:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
