.card {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    display: flex;
    flex-direction: column;
    width: 100%;

    .down-sec {
      background-color: #36191E;
      height: 15rem;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.inactive {
        background-image: url("../../assets/images/cards/redCardBackgrounds/contentGradient-inactive.png");
        background-repeat: round;
        height: 15.1875rem;
      }

      &.active {
        background-image: url("../../assets/images/cards/redCardBackgrounds/contentGradient-active.png");
        background-repeat: round;
        height: 15.1875rem;

        .row {
          .col {
            div {
              .shirt {
                .character {
                  background-color: #ffffff33;
                }
              }
            }
          }
        }
      }

      .row {
        display: flex;
        align-items: center;
        color: #D1CECF;

        .star {
          width: 4.25rem;
          height: 3.8125rem;
          margin-right: 1.1rem;
          margin-left: 0.7rem;
          margin-top: -0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: 'none';
        }

        .col {
          display: flex;
          flex-direction: column;

          .shirt {
            display: flex;
            align-items: center;
            height: 2.125rem;
            margin-bottom: 1.69rem;

            p {
              font-size: 2.625rem;
              margin: 0 0 0 0.525rem;
              // margin-left: 0.625rem;
              white-space: nowrap;
              width: 35rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            img {
              width: 2.4375rem;
              height: 2.8125rem;
              margin-top: 0.4rem;
            }

            .character {
              display: flex;
              background-color: rgb(255 255 255 / 20%);
              background-color: #686066;
              color: #212121;
              width: 2.1875rem;
              height: 2.1875rem;
              justify-content: center;
              align-items: center;
              border-radius: 0.1870rem;
              margin: 0 0.8rem 0 1.7rem;
              font-size: 1.875rem;
              font-family: 'RobotoRegular';
            }
          }

          .play-video {
            display: flex;
            align-items: center;
            height: 1.5625rem;
            // justify-content: space-evenly;
            margin-top: 0.625rem;
            margin-left: 0.5rem;

            p {
              font-family: RobotoRegular;
              font-size: 2.625rem;
              margin: 0 2rem 0 1.875rem;
              // margin-right: 2rem;
            }

            .score {
              color: #d8b948;
              margin-top: 0.3rem;
            }

            .glowering {
              color: white;
              // text-shadow: 0 0 0.625rem #ff242c;
              font-family: NotoSansCJKkrRegular;
              margin-right: 1.25rem;
              margin-left: 0;
              margin-top: 0.4rem;
              text-shadow: 0.025rem 0.025rem 1.75rem #ff0006, -0.025rem -0.025rem 1.75rem #ff0006, 0.025rem -0.025rem 1.75rem #ff0006, -0.025rem 0.025rem 1.75rem #ff0006;
            }

            .gold-play {
              outline: none;

              &:active {
                -webkit-filter: brightness(130%);
                filter: brightness(130%);
              }
            }

            .livee {
              background-color: rgb(155, 47, 48);
              color: rgb(255, 255, 255);
              text-align: center;
              border-radius: 0.625rem;
              margin: 1.875rem -0.5rem;
              margin-top: 2.5rem;
              font-size: 2rem;
              padding: 0.2rem 1.19rem;
              text-transform: uppercase;
            }

            img {
              width: 3.4rem;
              margin-top: 0.8rem;
            }
          }
        }


      }

      .num-container {
        display: flex;
        flex-direction: column;
        color: rgb(255, 255, 255);

        span {
          color: #fff;
        }

        .num-num {

          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 2.625rem;
          height: 1.625rem;

          p {
            font-family: RobotoRegular;
            // width: 2.625rem;s
            // margin-right: 1rem;
          }

          span {
            display: inline-block;
            text-align: center;
            font-family: RobotoRegular;
            width: 3.7rem;
            letter-spacing: -0.2rem;
            // margin-top: 0.1rem;
            // width: 4rem;

            &:nth-child(1) {
              // width: 2.2rem;
              // margin-right: 1rem;
            }

            &:nth-child(2),
            &:nth-child(3) {
              // width: 2.81rem;
              // margin-right: 1rem;
            }

            &:nth-child(4) {
              // width: 2.2rem;
              // margin-right: 1rem;
            }
          }

          .score {
            color: #D3A824;
            // width: -0.25rem;
            // letter-spacing: -0.4rem;
            letter-spacing: -0.3rem;
            // padding-left: 0.5rem;
            width: 3.5rem;
          }
        }

        .num-num {
          margin-top: 2.8rem;
        }
      }

    }

  }

}

.main-con {
  background-color: white;
  width: 100%;
  height: 7.6875rem;
  background-image: url("../../assets/images/cards/redCardBackgrounds/headerGradient-inactive.png");
  background-repeat: round;
  display: flex;
  justify-content: space-between;

  &.active {
    // background-image: url("../../assets/images/cards/redCardBackgrounds/headerGradient-active.png");
    background-image: url("../../assets/images/imgs/red-header-bg.png");
    background-repeat: round;
  }

  .sub-con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.8rem;
    color: #fff;

    p {
      color: #c8b273;
      font-size: 3rem;
      white-space: nowrap;
      width: 35rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .live-header-flag {
      width: 6.3rem;
      margin: 0 1.3rem;
    }

    .ball {
      width: 4rem;
    }
  }

  .sub2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem;


    img {
      width: 2.8rem;
      margin-right: 0.275rem;
    }

    .sq2 {
      background-color: #b92025
    }

    .square {
      height: 2.8rem;
      width: 2.8rem;
      margin-right: 1rem;
      border-radius: 0.25rem;
    }

    .sq1 {
      background-color: #fff30b;
      margin-right: 1rem;
    }

    .ball {
      // width: 2.5rem;
    }
  }
}

.section-num {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  p {
    font-family: RobotoRegular;
  }

  .live-btn {
    color: #fff;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;

    img {
      width: 5.43rem;
    }

    &.active-button {
      background-image: url("../../assets/images/cards/redCardBackgrounds/sideGradient-active.png");
      background-repeat: round;
      height: 7.5rem;
      width: 7.725rem;
      border: 0;

      img {
        width: 5.43rem;
        margin-top: 2.7rem;
        margin: 2.7rem -50% 0rem;

      }

      p {
        margin-top: -0.9rem;
        text-shadow: 0.125rem 0.125rem 1.25rem #f8c488;
      }
    }

    &.inactive-button {
      background: url('../../assets/images/imgs/red-body.png');
      background-repeat: round;

      img {
        width: 2.9rem;
        margin-top: 4rem;
        height: 2.9rem;
        cursor: pointer;
      }
    }


    &.inactive-with-plus {
      background-repeat: round;
      width: 7.725rem;
      height: 14.9rem;
      padding: 0;
    }

    &.card-button-arrow {
      background: url('../../assets/images/imgs/arrow-active.png');
      background-repeat: round;
    }

    p {
      font-size: 2.875rem;
      margin-top: 0.5rem;
      cursor: pointer;
    }
  }

  &.activee {
    .live-btn {
      background-image: url("../../assets/images/cards/redCardBackgrounds/sideGradient-active.png");
      background-repeat: round;

      img {
        width: 5.43rem;
        height: auto;
        margin: auto;
        margin-top: 2.7rem;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }

      p {
        margin-top: -0.9rem;
        text-shadow: 0.125rem 0.125rem 1.25rem #f8c488;
      }
    }
  }
}

.card-button-arrow {
  background: url('../../assets/images/imgs/red-red.png');
  background-repeat: round;
  height: 7.6875rem;
  width: 7.725rem;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background: url('../../assets/images/imgs/red-red-active.png');
    background-repeat: round;
  }
}


.card-button-arrow-active {
  background: url('../../assets/images/imgs/red-red-active.png');
  background-repeat: round;

}

// .active-active {
//   .card-button-arrow {
//     background: url('../../assets/images/imgs/red-red-active.png');
//     background-repeat: round;
//   }
// }