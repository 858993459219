.nLive-first-con {
  display: flex;
  border-bottom: 0.175rem solid #000;

  .nLive-main-live {
    display: flex;
    flex-direction: column;
    width: calc(100% - 7.4rem);

    .nLive-left {
      display: flex;
      flex-direction: column;
      width: 100%;

      .nLive-down-sec {
        height: 15.1875rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-image: url("../../assets/images/cards/blueCardBackgrounds/contentGradient-inactive.png");
        background-repeat: round;
        // padding-top: 1rem;

        &.active {
          background-image: url('../../assets/images/imgs/body-blue.png');
          background-repeat: round;
          height: 15.1875rem;

          .nLive-row {
            .nLive-col {
              div {
                .nLive-shirt {
                  .character {
                    background-color: rgba(255, 255, 255, 0.2);
                  }
                }
              }
            }
          }
        }

        .nLive-row {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #D1CECF;

          .nLive-star {
            width: 4.25rem;
            height: 3.8125rem;
            margin-right: 1.1rem;
            margin-left: 0.7rem;
            margin-top: -0.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .nLive-col {
            display: flex;
            flex-direction: column;

            .nLive-shirt {
              display: flex;
              align-items: center;
              height: 2.125rem;
              margin-bottom: 1.69rem;

              p {
                font-size: 2.625rem;
                white-space: nowrap;
                width: 35rem;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 0 0 0.525rem;
              }

              img {
                width: 2.4375rem;
                height: 2.8125rem;
                margin-top: 0.4rem;
              }

              .character {
                display: flex;
                background-color: rgba(255, 255, 255, 0.2);
                color: #212121;
                width: 2.1875rem;
                height: 2.1875rem;
                justify-content: center;
                align-items: center;
                border-radius: 0.187rem;
                margin: 0 1.25rem;
                margin-right: 1.875rem;
                font-size: 1.875rem;
                font-family: "RobotoRegular";
                margin: 0 0.8rem 0 1.7rem;
                background-color: #606575;
              }
            }

            .nLive-play-video {
              display: flex;
              align-items: center;
              height: 1.5625rem;
              margin-top: 0.625rem;
              margin-left: 0.25rem;
              color: #71ADD9;

              p {
                font-family: RobotoRegular;
                font-size: 2.625rem;
                margin: 0.9rem -0.3rem 0;
              }
            }
          }
        }
      }

    }
  }

  &:last-child {
    margin-bottom: 9.1875rem;
  }
}

.nLive-main-con {
  background-color: white;
  width: 100%;
  height: 7.6875rem;
  background-image: url("../../assets/images/cards/blueCardBackgrounds/headerGradient-inactive.png");
  background-repeat: round;
  display: flex;
  justify-content: space-between;

  .nLive-sub-con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.8rem;
    color: #fff;

    p {
      font-size: 3rem;
      white-space: nowrap;
      width: 55rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    .nLive-header-flag {
      width: 6.3rem;
      margin: 0 1.3rem;
    }

    .ball {
      width: 4rem;
    }

  }

  &.active {
    background: url('../../assets/images/imgs/header-blue.png');
    background-repeat: round;

    .nLive-sub-con {
      p {
        color: #fff;
        // font-size: 2.8rem;
      }
    }
  }

  &.card-button-blue {
    background: url('../../assets/images/imgs/arrow-blue-active.png');
    background-repeat: round;
  }
}

.nLive-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  .nLive-side-btn {
    color: #fff;
    border: none;
    width: 7.725rem;
    background: url('../../assets/images/imgs/blue-body-bg.png');
    background-repeat: round;
    font-size: 2.675rem;
    height: 15rem;
    padding: 0;
  }

  &.active-button {
    background-image: url("../../assets/images/cards/blueCardBackgrounds/sideGradient-active.png");
    background-repeat: round;

    .nLive-side-btn {
      background-color: transparent;
      text-shadow: 0 0 0.625rem #ffffff;
      height: 15rem;
      background: url('../../assets/images/imgs/blue-body-active-bg.png');
      background-repeat: round;
      outline: none;
    }

    &.card-button-blue {
      background: url('../../assets/images/imgs/arrow-blue-active.png');
      background-repeat: round;
    }
  }

  &.active-with-plus {
    background-repeat: round;
  }
}

.nLive-sub2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 7.1875rem;
}

.card-button-blue {
  background: url('../../assets/images/imgs/arrow-blue.png');
  background-repeat: round;
  height: 7.6875rem;
  width: 7.725rem;
  border: 0;

  &:active {
    background: url('../../assets/images/imgs/arrow-blue-active.png');
    background-repeat: round;
  }
}

.card-button-blue-active {
  background: url('../../assets/images/imgs/arrow-blue-active.png');
  background-repeat: round;
}