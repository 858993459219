.div-div2 {
    right: 2rem;
    justify-content: center;
    align-items: center;
    // cursor: pointer;
    border: 0;
    padding: 0;
    width: 10rem;
    height: 10rem;
    position: fixed;
    bottom: 11rem;
    border-radius: 50%;
    background: url('../../assets/images/imgs/btn_top.png');
    background-repeat: round;

    &:active {
        -webkit-filter: brightness(130%);
        filter: brightness(130%);
    }
}