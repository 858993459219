.modal-pop {
  // background: #fff;
  z-index: 999;
  width: 90%;
  margin: auto;
  position: fixed;
  top: 30%;
  color: #C0BDBF;
  width: 72.5625rem;
  left: 50%;
  transform: translate(-50%);

  .line {
    height: 0.1875rem;
    background: linear-gradient(to right, #2e2e37, #58585f 50%, #2e2e37);
  }

  .right-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 2.1875rem;
    border-right: 0.1875rem solid #000000;
    // margin-right: 0.0625rem;
    justify-content: center;

    div {
      display: flex;
      align-items: center;

      img {
        margin: 0;
        margin-right: 0.0625rem;

        &.cart-icon {
          margin-bottom: 0.0625rem;
          margin-right: 0;
          height: 5rem;
          width: 5.1875rem;
        }
      }
    }
  }

  .left-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 7.5rem;
    border-left: 0.1875rem solid #585867;

    &:active {
      -webkit-filter: brightness(130%);
      filter: brightness(130%);
      background: #4c4c5a9e;
    }
  }

  .exit-header {
    width: 8.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: initial;
    border: none;
  }

  .exit-img {
    width: 3rem;
    height: 1.875rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to top, #31313a, #3c3c47 50%, #3f3f4a);
    border-bottom: 0.1875rem solid #000000;
    font-size: 0.9375rem;
    height: 7.5rem;


    p {
      color: #fff;
      font-size: 3.3rem;
      margin-left: 5rem;
    }

    img {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  .modal-body {
    background-image: url("../../assets/images/modal/body.png");
    background-repeat: round;
    height: 36.25rem;
  }

  .modal-footer {
    background-image: url("../../assets/images/modal/footer.png");
    background-repeat: round;
    height: 10.125rem;
    padding: 0 0.9375rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 35.625rem;
      height: 8.1875rem;
      border-radius: 1rem;
      font-size: 3rem;
      border: none;
      color: #909090;
      box-shadow: 0.0625rem 0.125rem 0.3125rem -0.1875rem rgba(0, 0, 0, 0.75);
      background-image: url("../../assets/images/modal/button-gray.png");
      background-image: url('../../assets/images/imgs/btn.png');
      background-repeat: round;

      &:last-child {
        color: #000;
        margin-left: 1rem;
        background: url('../../assets/images/imgs/btn1.png');
        border-radius: 0;
        background-repeat: round;
      }
    }
  }
}

.title {
  margin-left: 5em;
  flex: 1
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgb(0, 0, 0, 0.802);
}

.container {
  padding: 3.4375rem 0 1rem 3.75rem;
  display: flex;
  width: 72.625rem;
  min-height: 36.5625rem;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 94%;

  .checkbox {
    color: #d1cecf;

    img {
      width: 2.375rem;
    }

    label {
      height: 2.5rem;
      display: flex;
      align-items: center;
      width: 97%;
    }

    .image-wrapper {
      height: 100%;
      display: flex;
      align-items: center;

      .icon {
        width: 6.3125rem;
        height: 3.875rem;
        margin-right: 1.875rem;
      }

      .checkbox-text {
        font-size: 2.8125rem;
      }
    }

    &:first-child {
      label {
        color: #fff;
        font-size: 3rem;
      }

      .image-wrapper {
        justify-content: flex-start;

        img {
          display: none;
        }
      }
    }
  }
}

.checkbox {
  display: inline-block;
  width: 50%;
}

.checkbox:first-child {
  width: 98%;
}

.all {
  width: 0.0625rem;
  height: 0.0625rem;
  color: #fff;
}


.exit-img {
  padding: 0.625rem;
  width: 0.9375rem;
  height: 1.0625rem;
}



.buttons-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  button {
    &:active {
      -webkit-filter: brightness(130%);
      filter: brightness(130%);
    }
  }
}

INPUT[type=checkbox]:focus {
  outline: 0.0625rem solid rgba(0, 0, 0, 0.2);
}

INPUT[type=checkbox] {

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2.6875rem;
  height: 2.0625rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.input-wrapper {
  background: #000;
  width: 3.0625rem;
  height: 3.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.75rem;
}

INPUT[type=checkbox]:checked {
  background: url("../../assets/images/other/check.png");
  background-repeat: round;
  width: 2.6875rem;
}