.footer-container {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;


footer {
  background-image: url("../../assets/images/other/footer.png");
  background-repeat: round;
  position: fixed;
  left: calc((100% - 1242px) / 2);
  bottom: 0;
  height: 9.1875rem;
  width: 100%;
  max-width: 1242px;
  box-shadow: 0 -0.625rem 0.625rem 0 #00000085;

  @media(max-width: 1242px) {
    left: 0;
  }

  .sub-container1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -3rem;
    width: 25rem;

    img {
      width: 4.1875rem;
      height: 4.125rem;
      // margin-left: 3rem;
    }

    .main-p {
      font-size: 3rem;
      margin-left: 2.5rem;
      color: #212121;
      font-weight: 600;
    }
  }

  .vl {
    border-left: 0.1875rem solid #9b824f;
    position: absolute;
    height: 37%;
    right: 50%;
  }

  .sub-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -2.5rem;
    color: #212121;

    p {
      font-size: 2.25rem;
      font-weight: 600;
      margin-top: 2rem;
    }

    span {
      color: #a33434;
      font-family: RobotoRegular;

      .noto-sans {
        font-family: NotoSansCJKkrRegular;
      }
    }

    .sub-p {
      margin-left: 2.3rem;
    }
  }

  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6.1rem 0 9.4rem;
    padding-right: 0;

    &:focus {
      -webkit-filter: brightness(130%);
      filter: brightness(130%);
    }

    &:hover {
      -webkit-filter: brightness(130%);
      filter: brightness(130%);
    }
  }
}
}