.container-main-header {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  padding: 0 0.9rem;
  color: #FFFFFF;
  height: 7.4375rem;


  .right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .dot {
      height: 1.1875rem;
      width: 1.1875rem;
      background-color: #9b3031;
      border-radius: 50%;
      display: inline-block;
    }

    .head-p {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #414141;
      border-radius: 1rem;
      font-family: RobotoRegular;
      font-size: 2.8125rem;
      padding: 0.4rem 1.5rem;
      min-width: 6.25rem;
      height: 4.1875rem;
    }

    p {
      font-size: 2.625rem;
      margin-left: 1rem;
    }
  }

  .button-container {
    align-items: center;
    color: #fff;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.6rem;
    // max-width: 70rem;
    // margin-right: 0.9rem;
  }

  .btn {
    border-radius: 1rem;
    // margin-left: 0.625rem;
    border: 0.25rem solid #b89c5e;
    background: linear-gradient(to top, #282828, #1c1c1c 50%, #111111 100%);
    background-repeat: round;
    color: #fff;
    height: 4.7rem;
    padding: 0 0.3rem 0 0;
    padding: 0;

    &:first-child {
      // width: 9.8125rem;
    }

    &.active {
      background-image: url("../../assets/images/other/mainHeaderButton-gold.png");
      background-repeat: round;
    }

    &:active {
      background-image: url("../../assets/images/other/mainHeaderButton-gold.png");
      background-repeat: round;
    }

    .button-pho {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      height: 5rem;
      color: #fff;
      margin-top: -0.4875rem;
      margin-right: 1rem;
      margin-left: 1rem;

      .icon {
        padding: 0;
        width: 3rem;
        margin-left: 0.15rem;
        margin-right: 0.3rem;
        margin-top: 0.2rem;
      }

      .arrow {
        width: 1.56rem;
        height: 0.75rem;
        margin-left: 1rem;
        margin-right: 0;
      }

      .text {
        margin-top: 2.4rem;
        margin-right: 0.2rem;
      }
    }
  }
}


* {
  box-sizing: border-box;
}

.select-box--selected-item {
  display: flex;
  height: 100%;
  width: 100%;
  padding-right: 0;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.select-box--selected-item .text {
  text-align: left;
}

.text {
  font-size: 2.425rem;
}


.white-arrow {
  width: 1.375rem;
  height: 0.6875rem;
  margin-left: 0.2rem;
}

.select-box--items {
  z-index: 28;
  // margin-top: 0.5rem;
  border-radius: 0.375rem;
  border: 0.25rem solid #b89c5e;
  background-color: #242424;
  font-size: 2.625rem;

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.select-box--box {
  z-index: 1;

  .select-box--container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0.25rem solid #b89c5e;
    background: linear-gradient(to top, #282828, #1c1c1c 50%, #111111 100%);
    background-repeat: round;
    border-radius: 0.375rem;
    height: 5.5rem;
    margin-left: 0.625rem;
    margin-top: 0.25rem;
    border-radius: 1rem;

    .icon {
      padding: 0;
      width: 3.3125rem;
      height: 3.375rem;
      margin-left: 0.5rem;
      margin-right: 0.1rem;
    }

    .text {
      font-size: 2.625rem;
    }
  }
}

.select-box--items .select-box--item:nth-of-type(1) {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.select-box--items .select-box--item:nth-of-type(3) {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}


.select-box--items div {
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

.select-box--items div:hover {
  background-color: #36363f;
}

.select-box--arrow-down {
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-top: 0.5rem solid #fff;
}

.select-box--arrow-up {
  transform: scaleY(-1);
  margin-top: -0.5625rem;
  width: 0.5rem;
}

.select {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: none;
  width: 16rem;
  font-size: 2.625rem;
  background: bottom;
  color: white;

  .arrow {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate();
  }

  .icon {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
  }

  .btn {
    padding: 0.5rem 3rem 0 3.5rem;
    padding: 0 2rem 0 1.2rem;
    margin-left: 0.1rem;
    min-width: 15rem;
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  select {
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    text-indent: 0.5rem;
    text-overflow: '';
    overflow: hidden;
    outline: 0;
    box-shadow: none;
    font-size: 2.625rem;
    padding: 0 0 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &::-ms-expand {
      display: none;
    }

    option {
      padding: 0;
    }
  }
}

.dropdown {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  color: #fff;
  border-radius: 1rem;
  // margin-left: 0.625rem;
  border: 0.25rem solid #b89c5e;
  background: linear-gradient(to top, #282828, #1c1c1c 50%, #111111 100%);
  background-repeat: round;
  color: #fff;
  height: 4.7rem;
  // padding: 0 0.3rem 0 0;

  &:active {
    background-image: url("../../assets/images/other/mainHeaderButton-gold.png");
    background-repeat: round;
  }

  .container {
    position: relative;
    width: 100%;
    min-height: 5rem;
    height: 5rem;
  }

  .select-overlay {
    border: 0;
    position: absolute;
    top: 0;
    left: -0.5rem;
    height: 22rem;
    width: 12rem;
    opacity: 0;
    cursor: pointer;
    border-radius: 1rem;
    // margin-left: 0.625rem;
    height: 5.3rem;

    option {
      padding: 0;
    }
  }

  .custom {
    font-size: 3rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3rem;
    height: 5rem;
  }

  .custom strong {
    font-size: 0.93rem;
    font-weight: 700;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.9rem;
    gap: 0.2rem;
  }

}